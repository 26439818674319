.side-panel-zoom-vc {
  /* height: calc(100vh - 120px); */
  /* position: absolute;
  top: 0 !important;
  left: 0 !important; */
  height: "100% !important";
  /* z-index: 3 !important; */
  /* height: max-content; */
  /*  top: var(--header-height);
  left: 0;
  height: calc(100vh - 120px); */
}
