.duration-div {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}
.session-details {
  font-size: 13px;
  margin-left: 30px;
  font-weight: 400;
  color: black;
  margin-top: -6px;
}
.session-details-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.session-price {
  border-style: solid;
  border-color: black;
  border-width: 2px;
  font-weight: 800;
  padding: 0px 3px;
  font-size: 14px;
  border-radius: 20% 30% 20% 30%;
}
.session-features-link {
  text-decoration: underline;
  color: rgb(2, 163, 232);
}
.mobile-sessions-display {
  display: none;
}
.searching {
  /* margin: 10px 15vw; */
  /* padding: 10px; */
  margin: 10px auto;
  max-width: 1100px;
  border-radius: 8px;
  position: relative;
  /* background-color: white; */
}

.sticky {
  position: sticky;
  top: 10px;
}

.searching > h2 {
  padding: 10px 18px;
  text-align: center;
  font-size: larger;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.searching-cont {
  border-radius: 8px;
  padding-top: 10px;
  /* position: relative; */
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.flex-search {
  display: grid;
  grid-template-columns: 30% 70%;
  /* display: flex;
    grid-gap: 10px;
    background-color: white; */
}

.duration-div {
  display: flex;
  width: 100%;
  /* height: fit-content; */
  justify-content: center;
  align-items: center;
}

/* 
.clearfix::after {
    content: "";
    clear: both;
    display: table;
} */

.search-pref {
  /* display: none; */
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  /* height: 97vh; */
  float: left;
  width: 320px;
  /* border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  /* margin: 600px 0 0; */
  /* margin-right: 10px; */
  /* padding: 0 20px;
    padding-right: 20px;
    border-right: 1px solid grey; */
  display: flex;
  flex-direction: column;
}

.static {
  width: 320px;
  position: fixed;
  top: 10px;
  height: 97vh;
  overflow-x: auto;
  overflow-x: hidden;
}

.static-a {
  margin-top: 625px;
}

.search-pref .as-per {
  margin-bottom: 15px;
}

.search-pref .as-per input {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

/* width */

.search-pref::-webkit-scrollbar {
  width: 8px;
}

/* Track */

.search-pref::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */

.search-pref::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */

.search-pref::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.charge-limit {
  margin-top: 20px;
  border-radius: 8px;
}

.charge-limit > div {
  margin: 10px 0;
  font-size: 18px;
}

.charge-limit label {
  margin: 0 20px 0 10px;
}

.charge-limit-box {
  display: inline;
  font-size: 20px;
  padding: 0 10px;
  text-align: center;
  width: 30%;
  /* margin-left: 10px; */
}

.searching select {
  padding: 10px;
  font-size: 18px;
  width: 100%;
}

.org {
  width: 100%;
  font-size: 18px;
  margin: 15px 0;
}

.org input {
  padding: 10px;
  margin-top: 5px;
  line-height: 18px;
  width: 100%;
}

.preference {
  margin: 10px 0;
}

.search-add-time-btn {
  color: blue;
}

.search-add-time-btn:hover {
  color: red;
}

.find-btn {
  display: block;
  margin: 20px auto;
  border: 2px solid #2ecc71;
  padding: 8px 16px;
  background-color: #2ecc71;
  color: white;
}

.find-btn:hover {
  background-color: white;
  color: #2ecc71;
}

.search-result {
  /* margin: 0 20px; */
  width: 70%;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  /* margin-left: auto; */
  float: right;
}

.search-result h3 {
  margin-bottom: 20px;
}

.search-result-item {
  /* border: 2px solid #1265a8; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  /* overflow: hidden; */
  padding: 10px 0px;
}

.search-result-item-head {
  /* justify-content: space-between; */
  display: grid;
  grid-template-columns: 80% 20%;
  width: 100%;
  /* margin-bottom: 20px; */
}

/* .interviewer-head {
  margin-bottom: 10px;
} */

.interviewer-head h4 {
  margin: 0;
}

.interviewer-logo {
  height: 50px;
  width: 50px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  margin: 0 5px;
  padding: 2px;
  /* border: 1px dashed black; */
  border-radius: 8px;
}

#int-logo {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0;
}

.interviewer-logo img {
  height: 100%;
  width: 100%;
}

.search-result-item p {
  margin: 0;
}

.search-result-item-profile {
  /* width: 100%; */
  /* overflow: hidden; */
  /* padding-right: 10px; */
  /* position: relative;
    margin-left: auto; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgb(231, 231, 231);
}

.search-result-item-profile img {
  display: block;
  border-radius: 50%;
  /* width: 80%; */
  /* width: 100px;
    height: 100px; */
  object-fit: cover;
  margin: 0 auto;
  padding: 4px;
  border-radius: 50%;
  border: 2px solid black;
  margin-bottom: 4px;
}

.search-view-btn {
  position: absolute;
  /* bottom: 0; */
  display: block;
  text-align: center;
  width: 100%;
  border-radius: 8px;
}

.search-view-btn:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.head-icon {
  border: 1px solid black;
  border-radius: 8px;
  height: 30px;
  width: 30px;
  justify-content: center;
  margin: 0 5px 0 10px;
  cursor: pointer;
  color: #1481db;
}

.head-icon:hover {
  /* background-color: rgba(0, 0, 0, 0.08); */
  color: #1265a8;
}

/* .searching .view-btn {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate( -50%, 0%);
    display: inline-block;
    border: 1px solid black;
    background-color: black;
    color: white;
    width: 100%;
    border-radius: 8px;
    border-radius: 0 0 50% 50%;
    margin: 0 auto;
    padding: 6px 8px;
    font-weight: 500;
    height: 35px;
    text-align: center;
    font-size: 0.7rem;
    z-index: 1;
} */

.searching .view-btn {
  /* visibility: hidden; */
  border: 2px solid black;
  padding: 6px 8px;
  background-color: black;
  color: white;
  width: 100%;
  font-size: 0.9rem;
  display: block;
}

.view-btn:hover {
  background-color: white;
  color: black;
}

.slot-list {
  display: grid;
  grid-template-columns: auto auto auto auto;
  list-style-type: none;
  padding: 0px 10px;
  margin: 0;
}

.details {
  justify-content: space-evenly;
  text-align: center;
  grid-gap: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.detail-head {
  color: grey;
  font-size: 16px;
}

.detail-body {
  font-size: 18px;
}

.price {
  background-color: green;
  color: white;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 8px;
  margin: 0;
}

.search-result-item-btns {
  justify-content: center;
  padding: 0 10px;
}

.request-btn {
  display: block;
  margin: 10px 0 5px;
  border: 1px solid #1265a8;
  background-color: #1265a8;
  color: white;
  padding: 4px 8px;
}

.request-btn:hover {
  background-color: white;
  color: #1265a8;
}

.selected {
  background-color: #6871b6;
  color: white;
  font-weight: bold;
}

/*Coupon card css*/

.coupon-container {
  width: 100%;
  /* background: #f0fff3; */
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 15px 0px;
}

.coupon-card {
  background: linear-gradient(135deg, #7158fe, #9d4de6);
  color: #fff;
  text-align: center;
  padding: 20px 40px;
  border-radius: 15px;
  box-shadow: 0 15px 13px -10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: relative;
}

.discounts-space h2 {
  padding-top: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 20px;
  text-align: center;
  margin-top: 8px;
}

.coupon-card h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.coupon-card p {
  font-size: 15px;
}

.coupon-row {
  display: flex;
  align-items: center;
  margin: 25px auto;
  width: fit-content;
}

#cpnCode {
  border: 1px dashed #fff;
  padding: 10px 20px;
  border-right: 0;
}

#cpnBtn {
  border: 1px solid #fff;
  background: #fff;
  padding: 10px 20px;
  color: #7158fe;
  cursor: pointer;
}

.coupon-circle1,
.coupon-circle2 {
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: inset 0px 10px 10px 0px rgba(0, 0, 0, 0.15);
}

.coupon-circle1 {
  left: -25px;
}

.coupon-circle2 {
  right: -25px;
}

.discounts-space {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.button-13 {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember", sans-serif;
  font-size: 14px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  /* width: 100px; */
}

.button-13:hover {
  background-color: #f7fafa;
}

.button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  outline: 0;
}

.flex-mobile-filter {
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0rem 0rem 0rem;
}

.MuiPickersToolbar-toolbar {
  background-color: #02a3e8 !important;
}

.MuiPickersDay-isSelected {
  background-color: #02a3e8 !important;
}

.MuiButton-label {
  color: #02a3e8 !important;
}

.MuiInputBase-input {
  /* text-align: center!important; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiPickersModal-withAdditionalAction:nth-child(1) {
  font-weight: bolder;
}
.session-details-container {
  justify-content: center;
}

@media screen and (max-width: 1170px) {
  .searching {
    margin-top: 35px;
  }
}

@media screen and (max-width: 1100px) {
  .searching {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 900px) {
  .searching {
    margin: 15px;
  }

  .searching-cont {
    flex-direction: column;
  }

  .search-pref {
    width: 100%;
    flex-direction: column-reverse;
  }

  .discounts-space {
    flex-direction: row;
  }

  .search-result {
    width: 100%;
  }
  /* .session-features-link {
    display: none;
  } */
  .session-price {
    display: none;
  }
  /* remove later */
  .laptop-sessions-display {
    display: none;
  }
  .mobile-sessions-display {
    display: inline;
  }
  .session-details-header {
    margin-left: 10px;
  }
  .session-details {
    display: none;
    /* remove later */
  }
}

@media screen and (max-width: 768px) {
  .searching > h2 {
    font-size: 0.9rem !important;
    margin-bottom: 0px;
    padding: 5px 18px;
  }
}

@media screen and (max-width: "600px") {
  .search-pref {
    position: sticky;
    flex-direction: column !important;
  }
  .css-qiwgdb.css-qiwgdb.css-qiwgdb {
    padding: 5px !important;
    font-size: 14px !important;
    text-align: left !important;
  }

  .MuiInputBase-input {
    padding: 5px !important;
    font-size: 14px !important;
    text-align: left !important;
  }
}

.MuiPagination-ul {
  flex-wrap: nowrap !important;
}

/* CSS */

.button-85 {
  padding: 5px 10px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  flex: 1;
}

.button-85:before {
  content: "";
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}
