.signup-employer {
  position: fixed;
  z-index: 15;
  height: 100vh;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.hide {
  display: none;
}

.signup-employer .login {
  background-color: white;
  position: relative;
  border-radius: 8px;
  max-width: 736px;
}

.signup-employer .cross {
  position: absolute;
  right: -20px;
  top: -40px;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.signup-employer .col {
  margin: 10px;
  padding: 0;
}

.signup-employer .cross:hover {
  color: rgb(255, 0, 0);
}

.signup-employer .login-head {
  background-color: white !important;
  color: #008a45;
  width: 100%;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  padding: 20px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.signup-employer .login-system {
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.signup-employer .col {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 15px;
  margin: 20px;
}

.signup-employer .login-system h1 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  padding: 5px 0 5px;
  margin-bottom: 0px;
}

.signup-employer .signin-btn {
  width: 100%;
  border: 1px solid #d9d9db;
  font-family: "Ubuntu", sans-serif;
  background-color: white;
  color: black;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  padding: 12px;
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-employer .signin-btn:hover {
  background-color: #dbdbdb;
}

.signup-employer .signin-btn img {
  width: 14px;
  margin-right: 10px;
}

.signup-employer .login-image {
  text-align: right;
}

.signup-employer .login-image img {
  height: auto;
}

@media only screen and (max-width: 992px) {
  .signup-employer .login-image {
    display: none;
  }
  .signup-admin .login-image {
    display: none !important;
  }
  .signup-employer .login-head {
    /* display: none; */
  }
  .signup-employer .cross {
    right: 10px;
    top: 10px;
    color: black;
  }
}
