body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.campus-ambassador-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background: linear-gradient(135deg, #f3f4f6, #e5e7eb);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 1s ease-out;
}

.title {
  font-size: 28px;
  color: #1f2937;
  margin-bottom: 20px;
  font-weight: 600;
}

.info-text {
  font-size: 16px;
  color: #4b5563;
  margin-bottom: 20px;
  line-height: 1.5;
  font-weight: 400;
}

.info-text strong {
  color: #1f2937;
  font-weight: 600;
}

.info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mark-button {
  background: linear-gradient(90deg, #4caf50, #66bb6a);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.mark-button:hover {
  background: linear-gradient(90deg, #66bb6a, #389b3c);
  transform: scale(1.02);
}

.modify-link {
  color: #1e40af;
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
  display: inline-block;
  transition: color 0.3s;
}

.modify-link:hover {
  color: #1d4ed8;
  text-decoration: underline;
}

.edit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 90%;
  max-width: 400px;
  animation: fadeIn 0.5s ease-out;
}

.edit-popup h3 {
  margin-bottom: 15px;
  color: #1f2937;
  font-size: 22px;
  font-weight: 500;
}

.edit-popup input {
  width: calc(100% - 20px);
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 16px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
}

.save-button {
  background: linear-gradient(90deg, #4caf50, #66bb6a);
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.save-button:hover {
  background: linear-gradient(90deg, #66bb6a, #4caf50);
  transform: scale(1.05);
}

.cancel-button {
  background: #f44336;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.cancel-button:hover {
  background: #e53935;
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
