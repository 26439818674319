.coupons-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.coupons-header {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.coupons-info {
  background-color: #e9f7fe;
  border-left: 4px solid #3498db;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.coupons-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.coupon-item {
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.coupon-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.coupon-item.used {
  text-decoration: line-through;
  opacity: 0.6;
}

.no-bookings-message,
.no-coupons-message {
  text-align: center;
  font-size: 18px;
  color: #7f8c8d;
  margin-top: 40px;
}

.highlight {
  color: #3498db;
  font-weight: bold;
}

.code {
  background-color: #e9f7fe;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: monospace;
}

@media (max-width: 600px) {
  .coupons-container {
    padding: 15px;
  }

  .coupons-header {
    font-size: 20px;
  }

  .coupon-item {
    font-size: 16px;
  }
}
