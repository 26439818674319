:root {
  --central-height: 580px !important;
  --central-width: calc((16 / 9) * var(--central-height)) !important;

  --side-height: 100px !important;
  --side-width: calc((16 / 9) * var(--side-height)) !important;
}
@media screen and (max-width: 1000px) {
  :root {
    --central-height: calc(100vh - 280px) !important;
    --central-width: calc((16 / 9) * var(--central-height)) !important;

    --side-height: 80px !important;
    --side-width: calc((16 / 9) * var(--side-height)) !important;
  }
}

/* video {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  background: none !important;
  outline: none !important;
  vertical-align: center !important;
  max-width: unset;
  transform: unset !important;
  transition-duration: unset !important;
  transition-timing-function: unset !important;
  -webkit-transform: unset !important;
}

video:hover {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  background: none !important;
  outline: none !important;
  vertical-align: center !important;
  max-width: unset;
  transform: unset !important;
  transition-duration: unset !important;
  transition-timing-function: unset !important;
  -webkit-transform: unset !important;
} */

.zoom-container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  min-height: 100vh !important;
  background-color: var(--main-dark);
}

.zoom-media-section {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  flex-direction: row !important;
  height: 100% !important;
  max-width: 100% !important;
  overflow-x: hidden;
  width: 100% !important;
}

.zoom-central-div {
  height: var(--central-height) !important;
  aspect-ratio: 16/9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: var(--main-dark) !important;
  border: 2px solid #ccc !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden !important;
}

.zoom-central-div-content {
  aspect-ratio: 16/9 !important;
  height: var(--central-height) !important;
  display: flex !important;
  justify-content: center !important;
}

.zoom-media-section .zoom-placeholder-img,
.zoom-right-container .zoom-placeholder-img {
  border-radius: 50% !important;
}

.zoom-media-section .zoom-placeholder-img {
  height: 100px !important;
  width: 100px !important;
}

.zoom-right-container .zoom-placeholder-img {
  height: 50px !important;
  width: 50px !important;
}

.zoom-right-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* height: var(--central-height); */
  margin-top: 20px;
  margin-right: 10px;
}

.zoom-right-container > div {
  height: var(--side-height) !important;
  aspect-ratio: 16/9 !important;
  background-color: var(--main-dark) !important;
  border: 2px solid #ccc;
  margin-bottom: 20px;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.zoom-right-container #self-view-canvas,
.zoom-right-container #self-view-video,
.zoom-right-container #self-share-screen-video-zoom,
.zoom-right-container #user-view-canvas,
.zoom-right-container #users-screen-share-content-canvas-zoom,
.zoom-right-container #self-share-canvas-zoom {
  scale: 0.25 !important;
  max-width: unset !important;
}

.zoom-right-container #self-view-video {
  scale: 0.28 !important;
  max-width: unset !important;
}
.zoom-right-container #users-screen-share-content-canvas-zoom {
  scale: unset !important;
}
.zoom-footer {
  width: 100% !important;
  display: flex !important;
  height: 55px;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: var(--main-darklg);
}

.zoom-footer-left,
.zoom-footer-right {
  margin: 10px 0 !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}

.zoom-footer-left button,
.zoom-footer-left div,
.zoom-footer-right button,
.zoom-footer-right div {
  border-radius: 50% !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 35px !important;
  height: 35px !important;
  font-size: 16px !important;
  margin: 0 5px !important;
  background-color: var(--primary-color);
}

.zoom-footer-left button,
.zoom-footer-right button {
  border-width: 1px !important;
  border-style: solid !important;
}
.zoom-footer-right {
  position: relative;
}

#self-share-screen-video-zoom,
#users-screen-share-content-canvas-zoom {
  width: var(--central-width) !important;
  height: auto !important;
  /* aspect-ratio: 16/9 !important; */
  /* changed from auto to 16/9  */
}
#self-view-canvas,
#self-view-video {
  transform: rotateY(180deg) !important;
  -webkit-transform: rotateY(180deg) !important;
}
.zoom-user-share {
  display: flex;
  align-items: center;
  justify-content: center;
}
.zoom-vc-navbar {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  background-color: var(--main-darklg);
  height: var(--header-height) !important;
  padding: 10px 20px !important;
}

.zoom-interview-type-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.zoom-timer-div-with-feedback {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.zoom-side-panel {
  width: 15%;
  height: 100% !important;
  max-height: calc(100vh - 120px);
  position: relative;
}

.zoom-central-div {
  width: 85%;
  max-width: fit-content;
}

.zoom-right-container {
  width: 20%;
  max-width: fit-content;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 10px;
  top: calc(7vh + 20px) !important;
  background-color: var(--main-darklg);
  border-radius: 10px;
  max-height: 80vh;
  z-index: 4;
  position: absolute;
  right: 0;
  height: fit-content;
}
.zoom-panel-buttons-mobile {
  display: none;
}
.zoom-timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.zoom-unread-notification {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -3px;
  left: 30px;
  color: white;
  font-weight: 500;
  font-size: 9px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: red;
}

@media screen and (max-width: 720px) {
  .zoom-vc-navbar {
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84px !important;
  }

  .zoom-navbar-logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .zoom-interview-type-header h1 {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .zoom-panel-buttons-mobile {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 5px;
  }
  .zoom-details-btn-mobile,
  .zoom-document-btn-mobile {
    width: 40px;
    margin: 2px 0; /* Add margin between buttons */
  }

  .zoom-details-btn-mobile button,
  .zoom-document-btn-mobile button {
    width: 100%; /* Make buttons take full width */
    padding: 2px 5px !important; /* Add padding to buttons */
    font-size: 10px !important; /* Adjust font size */
  }
  .notifications-zoom {
    position: absolute;
    right: 3px;
    top: 0;
  }
  .zoom-timer {
    position: absolute;
    top: 30px;
    font-size: 14px;
    right: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .feedback-review-button-zoom {
    position: absolute;
    top: 50px !important;
    right: 0;
  }
  .zoom-sidePanel-toggle-btn {
    display: none !important;
  }
  .zoom-in-meeting-timers {
    display: none;
  }
  .zoom-right-container-toggle-btn {
    display: none !important;
  }
  .zoom-right-container {
    position: unset !important;
    width: fit-content !important;
    height: fit-content !important;
    padding-right: 10px;
    padding-left: 10px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .zoom-right-container > div {
    margin-bottom: 10px;
    margin-right: 2px;
    margin-left: 2px;
  }
  .zoom-media-section {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-evenly !important;
    align-items: center !important;
  }
}
@media screen and (max-width: 700px) {
  .feedback-review-button-zoom {
    position: absolute;
    top: 80px !important;
    right: 0;
  }
}
