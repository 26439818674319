.profile-form-wrapper {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.profile-form-wrapper h1 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.profile-form-wrapper h2 {
  color: #666;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.profile-form-cont {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-form-text {
  width: 100%;
}

.cust-row {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.element {
  flex: 1;
  min-width: 250px;
}

.element label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
}

.element input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.element input:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.profile-photo {
  margin-bottom: 2rem;
}

.preview-logo {
  max-width: 200px;
  max-height: 200px;
  margin: 1rem 0;
  border-radius: 4px;
}

.filebase {
  margin: 1rem 0;
}

.error {
  color: #d32f2f;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.admin-info {
  margin: 2rem 0;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}

.submit-button {
  margin: 2rem 0;
  padding: 0.75rem 2rem;
  width: 100%;
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cust-row {
    flex-direction: column;
    gap: 1rem;
  }

  .element {
    width: 100%;
  }
}

/* Phone input custom styling */
.react-phone-input-2 {
  width: 100% !important;
}

.react-phone-input-2 .form-control {
  width: 100% !important;
  height: 42px !important;
  font-size: 1rem !important;
}
