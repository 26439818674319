.benefits-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background: linear-gradient(135deg, #ffffff, #f2f2f2);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  font-family: "Arial", sans-serif;
}

.benefits-image {
  width: 95%; /* Increased from 85% */
  max-width: 500px; /* Increased from 400px */
  border-radius: 10px;
  margin-bottom: 30px;
}

.benefits-content {
  width: 100%;
  max-width: 750px;
  text-align: center;
  padding: 20px;
  background: linear-gradient(135deg, #e0e0e0 0%, #f8f8f8 100%);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.benefits-title {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.8em;
  font-weight: bold;
}

.benefits-list {
  color: #555;
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.benefits-list li {
  margin-bottom: 15px;
  line-height: 1.6;
  position: relative;
  padding-left: 20px;
  font-size: 1.1em;
  transition: all 0.3s ease;
}

.benefits-list li:hover {
  background: rgba(0, 123, 204, 0.1);
  border-radius: 5px;
  padding-left: 25px;
}

.benefits-list .highlight {
  font-weight: bold;
  color: #007bcd;
  display: inline-block;
  margin-right: 8px;
  font-size: 1.15em;
}

@media (min-width: 768px) {
  .benefits-container {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
  }

  .benefits-content {
    text-align: left;
    padding: 30px;
    flex: 1;
  }

  .benefits-image {
    flex: 1;
    width: auto;
    max-width: 450px; /* Increased from 350px */
    margin: 0;
  }
}
