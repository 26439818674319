.responsibilities-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  font-family: "Arial", sans-serif;
  overflow: hidden;
}

.responsibilities-image {
  width: 100%;
  max-width: 500px; /* Increase the image size */
  border-radius: 10px;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
}

.responsibilities-content {
  width: 100%;
  max-width: 650px;
  text-align: center;
  padding: 20px;
  background: linear-gradient(135deg, #ece9e6 0%, #ffffff 100%);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.responsibilities-title {
  color: #222;
  margin-bottom: 15px;
  font-size: 2em;
  font-weight: bold;
}

.responsibilities-intro {
  color: #333;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.responsibilities-list {
  color: #555;
  list-style-type: none; /* Removes numbers from the list */
  padding-left: 0; /* Removes default padding */
  text-align: left;
  margin-bottom: 20px;
}

.responsibilities-list li {
  margin-bottom: 12px;
  line-height: 1.8;
  position: relative;
  padding-left: 20px;
}

.responsibilities-list li::before {
  content: "✔";
  position: absolute;
  left: 0;
  color: #0073e6;
  font-size: 1.2em;
}

.responsibilities-note {
  color: #444;
  font-size: 1em;
  margin-top: 20px;
  line-height: 1.6;
}

@media (min-width: 1100px) {
  .responsibilities-container {
    flex-direction: row;
    align-items: flex-start;
    width: 80%;
  }

  .responsibilities-image {
    margin-right: 30px;
    margin-bottom: 0;
  }

  .responsibilities-content {
    text-align: left;
  }

  .responsibilities-title {
    text-align: left;
  }

  .responsibilities-intro {
    text-align: left;
  }
}

.benefits-link {
  color: #0073e6;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.benefits-link:hover {
  text-decoration: underline;
  color: #005bb5;
}
