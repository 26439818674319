.zoom-chat-outer-div {
  position: absolute;
  right: 0;
  top: 7vh;
  height: calc(93vh - 60px) !important;
  width: 30%;
  max-width: 380px;
  min-width: 200px;
  max-height: 600px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 50;
  border-radius: 10px;
}
.zoom-chat-header {
  position: absolute;
  top: 0;
  padding: 5px 0;
  height: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.zoom-chat-header h6,
.zoom-chat-header i {
  margin: 5px 15px;
}
.zoom-main-chat-body {
  width: 100%;
  height: calc(100% - 60px - 48px);
  overflow: auto;
  padding: 10px 15px;
}
.zoom-chat-message-input {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 60px;
  position: absolute;
  bottom: 0;
  z-index: 100;
  padding: 5px 15px;
  background-color: #cfcfcf !important;
  border-radius: 0 0 10px 10px;
}
.zoom-chat-message-input .form-control {
  padding: 0;
  margin: 0;
  height: 35px;
  border-radius: 15px;
  flex: 1;
  font-size: 16px;
  padding: 2px 9px;
}

.zoom-chat-button-group {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}
.zoom-chat-button-group .btn {
  padding: 10px;
  border-radius: 5px;
  margin: 0 4px;
  background-color: white;
}
.zoom-chat-button-group .btn:hover {
  background-color: rgb(228, 228, 228);
}
.message-text-zoom {
  word-break: break-word !important;
}
.file-download-zoom-btn {
  cursor: pointer;
  font-size: 18px;
  float: right;
  padding: 4px;
  border-radius: 50%;
  border-width: 2px;
  border-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-download-zoom-btn:hover {
  background-color: #fff;
  color: black;
}
@media screen and (max-width: 1022px) {
  .zoom-chat-outer-div {
    right: auto;
    width: 100%;
    max-height: 700px;
    top: auto;
  }
}
@media screen and (max-width: 430px) {
  .zoom-chat-outer-div {
    max-width: 340px;
    top: auto;
  }
  .zoom-chat-message-input .form-control {
    padding: 2px 9px;
  }
  .zoom-chat-button-group {
    margin-left: 0;
  }
  .zoom-chat-button-group .btn {
    padding: 5px;
    margin: 0 2px;
  }
}
@media screen and (max-width: 1198px) {
  .zoom-chat-message-input .form-control {
    padding: 2px 9px;
    width: 35px;
    height: 30px;
  }
  .zoom-chat-button-group {
    margin-left: 5px;
  }
  .zoom-chat-button-group .btn {
    padding: 5px;
    margin: 0 2px;
  }
}
