.leaderboard-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.leaderboard-header {
  color: #2c3e50;
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.my-rank-box {
  background-color: #3498db;
  color: white;
  padding: 15px;
  border-radius: 8px;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rank-number {
  font-size: 28px;
  font-weight: bold;
  margin-left: 10px;
}

.leaderboard-list {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
}

.leaderboard-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s ease;
}

.leaderboard-item:last-child {
  border-bottom: none;
}

.leaderboard-item:hover {
  background-color: #f5f5f5;
}

.rank {
  font-size: 18px;
  font-weight: bold;
  min-width: 40px;
  text-align: center;
  color: #3498db;
}

.ca-info {
  margin-left: 20px;
  flex-grow: 1;
}

.name {
  font-size: 18px;
  font-weight: 500;
  color: #2c3e50;
}

.institute {
  font-size: 14px;
  color: #7f8c8d;
  margin-top: 5px;
}

.formula-box {
  margin-top: 20px;
  background-color: #e9f7fe;
  border-left: 4px solid #3498db;
  padding: 15px;
  border-radius: 4px;
}

.formula {
  font-weight: bold;
  margin-bottom: 10px;
  color: #2c3e50;
}

.note {
  font-size: 14px;
  line-height: 1.5;
  color: #34495e;
}

.code {
  background-color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: monospace;
  color: #3498db;
}

/* Scrollbar styles for webkit browsers */
.leaderboard-list::-webkit-scrollbar {
  width: 8px;
}

.leaderboard-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.leaderboard-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.leaderboard-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 600px) {
  .leaderboard-container {
    padding: 15px;
  }

  .leaderboard-header {
    font-size: 24px;
  }

  .my-rank-box {
    font-size: 18px;
  }

  .rank-number {
    font-size: 24px;
  }

  .leaderboard-item {
    padding: 10px;
  }

  .rank {
    font-size: 16px;
  }

  .name {
    font-size: 16px;
  }

  .institute {
    font-size: 12px;
  }
}
.my-rank-row {
  background-color: #f1f1f1; /* Light gray background */
  font-weight: bold;
}

.congratulations-box {
  background-color: #f0f8ff;
  border: 2px solid #4caf50;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
  text-align: center;
}

.congratulations-message {
  font-size: 18px;
  font-weight: bold;
  color: #4caf50;
  margin-bottom: 10px;
}

.encouragement {
  font-size: 16px;
  color: #333;
}
