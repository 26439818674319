.bookings-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bookings-header {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.share-message {
  background-color: #e9f7fe;
  border-left: 4px solid #3498db;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.names-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.names-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.name-item {
  flex: 1;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  text-align: center;
  font-weight: 500;
}

/* Additional CSS for the header row */
.names-header .name-item {
  font-weight: 700;
  background-color: #3498db;
  color: white;
}

.no-bookings-message {
  text-align: center;
  font-size: 18px;
  color: #7f8c8d;
  margin-top: 40px;
}

.highlight {
  color: #3498db;
  font-weight: bold;
}

.code {
  background-color: #e9f7fe;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: monospace;
}

@media (max-width: 600px) {
  .bookings-container {
    padding: 15px;
  }

  .bookings-header {
    font-size: 20px;
  }

  .names-row {
    flex-direction: column;
  }
}
