#home-page {
  background-color: white;
}

.carousel-item img {
  object-fit: cover;
  height: 500px;
}

.get-started {
  text-align: center;
  padding-top: 20px;
  background: linear-gradient(to right, #f5da2e 50%, #00b0f0 50%);
}

.get-started .left {
  color: black;
}

.started-item {
  padding: 10px 20px;
}

.started-item p {
  font-size: 24px;
}

.right {
  color: white;
}

.create-account-btn {
  /* border: 2px solid #00BBF0; */
  /* background-color: #3C6E71; */
  background-color: hsl(0, 0%, 10%);
  color: white;
  border: 2px solid black;
  padding: 8px 12px;
  font-size: 24px;
  margin-bottom: 30px;
  transition: all 0.2s ease-in-out;
}

.create-account-btn:hover {
  background-color: #fff;
  color: black;
  transition: all 0.2s ease-in-out;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
  overflow: hidden;
  border-radius: 16px;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
  border-radius: 30px;
}

#line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 9;
  -webkit-line-clamp: 9;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.background {
  /* background: url('../../../public/successbg.svg'); */
  background: url("../../../public/backgroundbg.svg") no-repeat center center;
  background-repeat: no-repeat;
  /* background-size: auto; */
  background-size: cover;
  position: relative;
}

.background > h2,
.background > h6,
.background > div {
  position: relative;
}

.background::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  /* // background-color: rgba(152, 66, 211, 0.63); */
}

#testimonial {
  /* background: url('../../../public/successbg.svg'); */
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px !important;
  /* background-image: linear-gradient(174.2deg, rgba(255, 244, 228, 1) 7.1%, rgba(240, 246, 238, 1) 67.4%); */
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 50% !important;
  border-radius: "8px";
}

.allSuccessStoriesBtn {
  margin: 40px 20px 30px 19px;
  border-radius: 10px;
  padding-bottom: 30px;
  border: 1px solid;
  padding: 7px 15px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  color: #1976d1;
  font-size: 1.2rem;
}

.allSuccessStoriesBtn:hover {
  /* filter: brightness(80%); */
  background-color: rgb(235, 235, 235);
}

#plans {
  display: flex;
  justify-content: center;
}

/* .left */

@media only screen and (max-width: 900px) {
  .carousel-item img {
    height: 400px;
  }
}

@media only screen and (max-width: 550px) {
  .carousel-item img {
    height: 300px;
  }
  .get-started {
    flex-direction: column;
  }
  .how-it-works-heading .MuiButtonBase-root {
    padding: 6px 8px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 430px) {
  .how-it-works-heading .MuiButtonBase-root {
    padding: 3px 5px;
    font-size: 10px;
  }
}
@media only screen and (max-width: 350px) {
  .how-it-works-heading .MuiButtonBase-root {
    padding: 3px 5px;
    font-size: 9px;
  }
}
/* 
.how-it-works-heading .MuiTabs-flexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
} */
