.signups-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.signups-header {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.share-message {
  background-color: #e9f7fe;
  border-left: 4px solid #3498db;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.signups-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.signups-table th,
.signups-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.signups-table th {
  background-color: #3498db;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.signups-table tr:hover {
  background-color: #f5f5f5;
  transition: background-color 0.3s ease;
}

.name-column {
  font-weight: 500;
}

.no-signups-message {
  text-align: center;
  font-size: 18px;
  color: #7f8c8d;
  margin-top: 40px;
}

.highlight {
  color: #3498db;
  font-weight: bold;
}

.code {
  background-color: #e9f7fe;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: monospace;
}

@media (max-width: 600px) {
  .signups-container {
    padding: 15px;
  }

  .signups-header {
    font-size: 20px;
  }

  .signups-table th,
  .signups-table td {
    padding: 8px;
  }
}
